// Locale
// import { en } from '../../locale'
import colors from "vuetify/lib/util/colors";
import whitelabelTheme from "./whitelabelTheme.json";

export const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)

  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: false, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: "VerticalSix", //VerticalCompactSidebar,VerticalSaasLayout,VerticalSidebar,HorizontalBar,VerticalSix
  isLoading: false,
  isDark: !!whitelabelTheme?.colors?.darkMode,
  verticalSidebarDrawerColor: "white", // primary,white,sucsess,warning,etc
  appBarColor: "white", // primary,white,sucsess,warning,etc
};

const mq = window.matchMedia("(prefers-color-scheme: light)");

// export const vuetify = new Vuetify({
//   theme: { dark: mq.matches }
// });

// console.log(mq);

// mq.addEventListener("change", e => {
//   console.log(mq);
//   this.$vuetify.theme.dark = e.matches;
// });

export const texts = {
  footerDescription: whitelabelTheme?.texts?.footerDescription || "",
  loginTitle: whitelabelTheme?.texts?.loginTitle || "Faça login para continuar",
};
export const socialNetworks = whitelabelTheme?.socialNetworks || [];
export const appBarColor = whitelabelTheme?.colors?.appBar || "#242939";
export const footerBar = whitelabelTheme?.colors?.footerBar || "#000677";
export const hidePoweredBy = !!whitelabelTheme?.hidePoweredBy;
export const email = whitelabelTheme.email || "";
export const whatsapp = whitelabelTheme.phone || "";
export const name = whitelabelTheme.name || "Plataforma";
export const legalInfo = whitelabelTheme.legalInfo || {};
export const videoCover =
  whitelabelTheme.logos.videoCover ||
  "https://firebasestorage.googleapis.com/v0/b/ticketme-2f29d.appspot.com/o/background-720.695e0cc8.webm?alt=media&token=295ab420-cb3f-4706-96d4-e2a11166d94a&_gl=1*wae51d*_ga*MTIyNzQ2NjE1NS4xNjk4NDI0NDk4*_ga_CW55HF8NVT*MTY5ODQyNDQ5OS4xLjEuMTY5ODQyNDU4NS40OC4wLjA";
export const themeWhite = whitelabelTheme?.colors?.light;
export const themeDark = whitelabelTheme?.colors?.dark;
export const logos = whitelabelTheme?.logos || {};
export const active = whitelabelTheme?.active;
export const darkMode = !!whitelabelTheme?.colors?.darkMode;
export const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },

  rtl: false,

  theme: {
    // dark: mq.matches,
    dark: !!whitelabelTheme?.colors?.darkMode,

    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#5e5ce6",
        secondary: "#304156",
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,
        dark: "#242939",
        background: "#f2f3f8",

        ...(whitelabelTheme?.colors?.light || {}),
      },
      dark: {
        primary: "#5e5ce6",
        secondary: "#304156",
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,

        ...(whitelabelTheme?.colors?.dark || {}),
      },
    },
  },
};
